import ROUTES from './routes'

import { getPathFromTemplate } from 'utils/routes'

export const routes = Object
  .entries(ROUTES)
  .map(([id, route]) => ({ ...route, id }))

export const getRouteById = (id) => (
  ROUTES[id] || ROUTES.home
)

export const getPathById = (id, params) => {
  const route = getRouteById(id)
  let path = route.path

  if (path && params) {
    path = getPathFromTemplate(route.linkTemplate || path, params)
  }

  return path
}

export const getRootPathById = (id, params) => {
  const route = getRouteById(id)
  let path = route.rootPath || route.path

  if (path && params) {
    path = getPathFromTemplate(route.linkTemplate || path, params)
  }

  return path
}

export default routes
