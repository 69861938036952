import _slugify from 'slugify'
import device from './device'

const slufigyDefaults = {
  replacement: '-',
  lower: true
}

_slugify.extend({
  '&': 'et'
})

export const slugify = (string, options = {}) => {
  return _slugify(string, { ...slufigyDefaults, ...options })
}

/**
 * @function capitalizeFirst
 * Capitalize only the first letter of a given string
 * @param { string } str - The string to modify
 * @param { boolean=false } allFirstLetters - Capitalize all words' first letter or just the first word's
 * @returns { string } The modified string
 * @example
 * // returns 'Hello World'
 * capitalizeFirst('hello world', true)
 * // returns 'Hello world'
 * capitalizeFirst('hello world')
 * capitalizeFirst('hello world', false)
 * capitalizeFirst('Hello World')
 * capitalizeFirst('HELLO WORLD')
 */
export const capitalizeFirst = (str = '', allFirstLetters = false) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str
  }

  if (allFirstLetters === true) {
    return str.split(' ').map(capitalizeFirst).join(' ')
  }

  return str
    .toLowerCase()
    .split('')
    .map((char, i) => i === 0 ? char.toUpperCase() : char)
    .join('')
}

export const trimString = (str = '', maxLength = undefined) => {
  if (maxLength && str.length > maxLength) {
    let text = str.substring(0, maxLength - 1)
    text = text.split(' ')
    text.pop()
    text.push('...')
    text = text.join(' ')

    return text
  }

  return str
}

export const getPreposition = (str) => {
  if (typeof str !== 'string') return ''
  // TODO: add vowels with accent to the list
  if (['a', 'e', 'i', 'o', 'u', 'y'].includes(str[0])) {
    return 'd\''
  }
  return 'de '
}

/**
 * copy a string to the clipboard
 * @param str
 */
export const copyToClipBoard = (str) => {
  if (device.isIE()) {
    window.clipboardData.setData('Text', str)
  } else {
    const el = document.createElement('textarea')
    el.value = str
    document.body.appendChild(el)
    el.select()
    try {
      document.execCommand('copy')
    } catch (e) {
      console.log('can\'t copy to clipboard')
    } finally {
      document.body.removeChild(el)
    }
  }
}
