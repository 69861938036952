import PathMatch from 'path-match'
import Url from 'url-parse'

const createRouteMatch = path => (
  path === '/'
    ? PathMatch()(path)
    : PathMatch({ end: false })(path)
)

/**
 * getPathFromTemplate function
 * @param  {string} template the route path template
 * @param  {object} params   an key value list of the route parameters
 * @return {string}          the resulting route path
 */
export const getPathFromTemplate = (template, params) => {
  if (!template) {
    return '/'
  }

  if (!params || Object.keys(params).length === 0) {
    return template
  }

  let newPath = template

  Object
    .entries(params)
    .forEach(
      ([key, value]) => {
        newPath = newPath.replace(`:${key}`, value)
      }
    )

  return newPath
}

/**
 * matchRoute function
 * matches a route definition against a path
 * @param  {string} route the route definition
 * @param  {string} path  the actual path to try and mathc
 * @return {object}       returns an object describing the matching result
 */
export const matchRoute = (route, path) => {
  const isRoot = route[0] === '/'
  const fullRoute = isRoot ? route : '/:root*/' + route
  // Generate route matching function
  const match = createRouteMatch(fullRoute)
  // Parse the current path to extract the pathname
  const parsedPath = new Url(path, true)
  // Match the pathname against the route
  let query = match(parsedPath.pathname)

  const hasRoute = query !== false

  // Merge path params, query string params and url hash
  if (hasRoute && parsedPath.query) {
    query = query || {}
    if (parsedPath.hash) {
      query.pathHash = parsedPath.hash
    }
    Object
      .entries(parsedPath.query)
      .map(([key, value]) => {
        query[key] = query[key] || value
      })
  }
  let root = path
  if (isRoot && hasRoute) {
    root = getPathFromTemplate(route, query)
  } else if (query !== false) {
    root = `/${Array.isArray(query.root) ? query.root.join('/') : ''}`
  }
  return {
    isRoot,
    hasRoute,
    query: !query || Object.keys(query).length > 0
      ? query
      : null,
    root
  }
}
/**
 * matchRouteArray function
 * matches an array of route definitions against a path
 * @param  {string[]} routes the route definition
 * @param  {string} path     the actual path to try and mathc
 * @return {object}          returns an object describing the matching result
 */
export const matchRouteArray = (routes, path) => {
  const firstMatch = routes.find(route => {
    const { hasRoute } = matchRoute(route, path)
    return hasRoute
  })

  return matchRoute(firstMatch || routes[0], path)
}
