export const BREAKPOINT_MOBILE_S = 568
export const BREAKPOINT_MOBILE = 768
export const BREAKPOINT_TABLET = 992
export const BREAKPOINT_DESKTOP = 1024
export const BREAKPOINT_DESKTOP_XL = 1200

export const breakpoints = {
  xxs: 320,
  xs: 568,
  sm: 667,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600
}

const cache = {
  screenWidth: null
}

const isIOS = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

const isAndroid = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return navigator.userAgent.toLowerCase().indexOf('android') !== -1
}

const isMobile = (noCache = false) => {
  if (typeof window === 'undefined') {
    return false
  }
  return getScreenWidth(noCache) < BREAKPOINT_MOBILE
}

const isTablet = (noCache = false) => {
  if (typeof window === 'undefined') {
    return false
  }
  return getScreenWidth(noCache) < BREAKPOINT_TABLET && getScreenWidth(noCache) >= BREAKPOINT_MOBILE
}

const isDesktop = (noCache = false) => {
  if (typeof window === 'undefined') {
    return false
  }
  return getScreenWidth(noCache) >= BREAKPOINT_DESKTOP
}

const isIE = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return /MSIE|Trident/.test(navigator.userAgent)
}

const getScreenWidth = (noCache = false) => {
  if (typeof window === 'undefined') {
    return 0
  }
  if (!cache.screenWidth || noCache) {
    cache.screenWidth = window.innerWidth
  }
  return cache.screenWidth
}

const getScreenHeight = (noCache = false) => {
  if (typeof window === 'undefined') {
    return 0
  }
  if (!cache.screenHeight || noCache) {
    cache.screenHeight = window.innerHeight
  }
  return cache.screenHeight
}

export default {
  isIOS,
  isAndroid,
  isIE,
  isMobile,
  isTablet,
  isDesktop,
  getScreenWidth,
  getScreenHeight
}
